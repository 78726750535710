<template>
  <v-row>
    <v-checkbox
      v-if="showContratAnnule"
      v-model="btnContratAnnule"
      class="ml-4"
    >
      <span
        slot="label"
      >{{ $t('contract.Show_inactive_contracts') }}</span>
    </v-checkbox>

    <template v-for="(contrat, index) in getContrats">
      <v-col
        :key="index"
        cols="12"
      >
        <base-material-card
          inline
          :icon="getContratTypeIcon(contrat.type)"
          :color="getContratTypeColor(contrat.type)"
          :title="getContratName(contrat.id)"
          class="px-5 py-3"
        >
          <!--  Numéro de téléphone-->
          <h4 class="card-title font-weight-light mt-2 ml-2">
            <!--  Edit Contract-->
            <!--  On affiche le bouton 'Contrat seulement pour le module opération'-->
            <!--  Important remettre l<autre bouton en bas-->
            <!-- <v-btn
              fab
              dark
              x-small
              color="success"
              :title="$t('Contract')"
              @click="editContract(contrat.id, index)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn> -->
            <v-btn
              v-if="showRouteContract"
              fab
              dark
              x-small
              color="success"
              :title="$t('Contract')"
              @click="editContract(contrat.id, index)"
            >
              <v-icon>{{ expand[index].state && showContract ? 'mdi-pencil-plus' : 'mdi-pencil' }}</v-icon>
            </v-btn>

            <!--  Facture-->
            <v-btn
              :class="showRouteContract ? 'ml-2' : ''"
              fab
              light
              x-small
              color="white"
              :title="$t('contract.Bill')"
              @click="showBill(contrat.id, index)"
            >
              <v-icon>{{ expand[index].state && !showContract ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
            </v-btn>

            <!--  Numéro de contrat-->
            <!--  Si contrat inactif ou suspendu-->
            <template v-if="contrat.statut === 7 || contrat.statut === 4">
              <!--  Contrat suspendu-->
              <template v-if="contrat.statut === 7">
                <span :class="showRouteContract ? 'ml-4 red--text font-weight-bold' : 'red--text font-weight-bold'">
                  Contrat suspendu
                </span>
                <v-icon
                  left
                  dark
                  color="red accent-3"
                >
                  mdi-close-octagon
                </v-icon>
                <br>
                <span
                  :class="showRouteContract ? 'descriptiontestSuspend1' : 'descriptiontestSuspend'"
                >
                  {{ contrat.no | phoneNumberFormat }}
                </span>
              </template>
              <!--  Contrat inactif-->
              <template v-if="contrat.statut === 4">
                <strike
                  :class="showRouteContract ? 'ml-4 red--text' : 'red--text'"
                >
                  {{ contrat.no | phoneNumberFormat }}
                </strike>
              </template>
            </template>
            <!--  Pas annulé-->
            <span
              v-else
              :class="showRouteContract ? 'ml-4' : ''"
            >
              {{ contrat.no | phoneNumberFormat }}
            </span>

            <!-- Type de forfait-->
            <!-- Téléphone-->
            <v-tooltip
              v-if="contrat.forfait_id === 1 || contrat.forfait_id === 3"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="green"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-phone
                </v-icon>
              </template>
              <span>{{ $t('contract.Voice') }}</span>
            </v-tooltip>
            <!-- Data-->
            <v-tooltip
              v-if="contrat.forfait_id === 2 || contrat.forfait_id === 3"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="blue"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-wifi
                </v-icon>
              </template>
              <span>{{ $t('contract.Data') }}</span>
            </v-tooltip>
            <!--  Bouton pour payer-->
            <!--  Pour corporatif 2 seulement-->
            <!--  Si Paiement est null, il doit payer la facture-->
            <v-btn
              v-if="checkPaimentSummary(contrat)"
              color="pink accent-4"
              small
              class="ma-2"
              @click="showBill(contrat.id, index)"
            >
              <v-icon
                left
                dark
                color="yellow accent-3"
              >
                mdi-alert
              </v-icon>
              {{ checkPaimentText(contrat.Factures) }}
            </v-btn>
          </h4>

          <!--  Description-->
          <!--  Si il y a un bouton Contrat-->
          <p
            v-if="showRouteContract"
            class="grey--text description"
          >
            {{ contrat.description }}
          </p>
          <!--  Si il n'y a pas de bouton Contrat-->
          <p
            v-else
            class="grey--text descriptionWithoutButton"
          >
            {{ contrat.description }}
          </p>

          <!--  UBR (code facturation) pour contrat UBR-->
          <p
            v-if="contrat.type === 1"
            class="descriptionWithoutButton"
          >
            {{ $t('contract.Billing_code') }}: {{ contrat.code_facturation }}
          </p>

          <!-- Paiement automatique carte de crédit-->
          <!-- Pour le Dashboard seulement-->
          <div
            v-if="contrat.type === 2 && contrat.Client"
            class="descriptionWithoutButton"
          >
            <!-- On désactive pour les opérations (showRouteContract)-->
            <!-- On affiche la sélection entre le paiement manuel et la carte de crédit si la variable 'parameterModePaiement' est 3 (paiement mixte)-->
            <v-radio-group
              v-if="parameterModePaiement === '3'"
              v-model="contrat.paiementAutomatique"
              :disabled="showRouteContract"
              row
              append-icon="mdi-credit-card"
            >
              <!-- Paiement manuel-->
              <v-radio
                :label="$t('order.Manual_payment_short')"
                :value="false"
                @click="changePaiementManuel(contrat.id, contrat.ClientId)"
              />

              <!-- Si le client n'a pas de carte de crédit, on désactive ce bouton-->
              <v-radio
                :disabled="Boolean(!contrat.Client.Inscription_paiements.length)"
                :label="$t('order.Automatic_payment_short')"
                :value="true"
                @click="changePaiementAutomatique(contrat.id, contrat.ClientId, contrat.Client.Inscription_paiements[0].id)"
              />
            </v-radio-group>

            <!-- Si le mode de paiement est 3 ou 2, le client peut mettre une carte-->
            <template v-if="parameterModePaiement === '3' || parameterModePaiement === '2'">
              <!-- Choix de la carte de crédit-->
              <span>{{ $t('order.Credit_card') }}</span>

              <v-list
                shaped
              >
                <v-list-item-group
                  v-model="contrat.InscriptionPaiementId"
                  append-icon="mdi-credit-card"
                >
                  <template v-for="(card, i) in contrat.Client.Inscription_paiements">
                    <v-divider
                      v-if="!card"
                      :key="`divider-${i}`"
                    />

                    <!-- On désactive si il est sélectionné-->
                    <v-list-item
                      v-else
                      :key="`item-${i}`"
                      :disabled="disabledCard(contrat.InscriptionPaiementId, card.id)"
                      :value="card.id"
                      active-class="green--text text--accent-4"
                      @change="changeCreditCard(contrat.id, card.id, contrat.ClientId)"
                    >
                      <template #default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            color="green"
                          />
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{ card.nom_titulaire }}</v-list-item-title>
                          <v-list-item-subtitle>{{ `${$t('profile.automatic_payment.Credit_card_ending')} ${card.dernier_chiffre}` }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>

              <!-- Ajout d'une carte -->
              <div
                v-if="$route.name === 'Dashboard'"
              >
                <a
                  class="text-h4 mb-1"
                  href=""
                  @click.prevent="addCard(contrat.id)"
                >{{ $t('profile.automatic_payment.Add_card') }}</a>
              </div>

              <div
                v-if="!contrat.Client.Inscription_paiements.length && $route.name === 'Dashboard'"
              >
                <span>{{ $t('profile.automatic_payment.Add_card_message') }}</span>
              </div>
            </template>
          </div>

          <v-expand-transition>
            <!--  Contrat-->
            <template v-if="showContract">
              <v-card
                v-show="expand[index].state"
                flat
                class="ml-2"
              >
                <!--  Contrat-->
                <v-card-text>
                  <client-contract-create-edit
                    ref="clientContratEdit"
                    :create-edit-info="createEditInfo"
                    :color="getContratTypeColor(contrat.type)"
                    @create-update-contract="createUpdateContract"
                    @cancel-contract="cancelContract"
                  />
                </v-card-text>
              </v-card>
            </template>
            <template v-else>
              <!--  Facture Mensuelle-->
              <v-card
                v-show="expand[index].state"
                flat
                class="ml-2"
              >
                <v-card-text>
                  <!--  Facture Mensuelle-->
                  <facture-mensuelle
                    :factures="contrat.Factures"
                    :parameter-fournisseur-paiement="parameterFournisseurPaiement"
                    :parameter-mode-paiement="parameterModePaiement"
                    :contract-type="contrat.type"
                    @reset-contrats="resetContrats"
                  />
                </v-card-text>
              </v-card>
            </template>
          </v-expand-transition>
        </base-material-card>
      </v-col>
    </template>
    <!--  Dialog facture catégorie 2 (Suspendu)-->
    <v-dialog
      v-model="factureCategorie2Dialog.state"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="factureCategorie2Dialog.state = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="body-1 text-center">
          <v-row>
            <v-col
              cols="12"
            >
              <v-icon
                size="78px"
                color="success"
              >
                mdi-check-circle
              </v-icon>
            </v-col>
            <v-col
              cols="12"
            >
              {{ factureCategorie2Dialog.message }}
            </v-col>
          </v-row>

          <v-btn
            class="mt-2 ml-4"
            color="info"
            depressed
            default
            rounded
            @click="factureCategorie2Dialog.state = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  // Component
  import ClientContractCreateEdit from '@/components/Client/Contrat/ClientContractCreateEdit'
  import FactureMensuelle from '@/components/Client/Facture/FactureMensuelle'
  // Service
  import ContratsService from '@/services/01Cell/ContratsService'
  import ClientsService from '@/services/01Cell/ClientsService'
  import FacturesService from '@/services/01Cell/FacturesService'
  // import PaiementsInscriptionService from '@/services/01Cell/PaiementsInscriptionService'
  // Other
  import { EventBus } from '@/utils/event-bus'
  import { mapGetters, mapMutations } from 'vuex'
  import Swal from 'sweetalert2'

  export default {
    name: 'ClientContractComponent',
    components: {
      FactureMensuelle,
      ClientContractCreateEdit,
    },
    props: {
      clientId: Number,
      clientContrats: Array,
    },
    data () {
      return {
        expand: [],
        showContract: false,
        showRouteContract: false,
        contrats: [],
        createEditInfo: {
          icon: 'mdi-pencil',
          title: `${this.$i18n.t('Edit')} : ${this.$i18n.t('Contract')}`,
        },
        factureCategorie2Dialog: {
          state: false,
          message: '',
        },
        btnContratAnnule: false,
        // facturesChart: {
        //   data: {
        //     labels: ['Janv', 'Fév', 'Mars', 'Avr', 'Mai'],
        //     series: [
        //       [32, 34.6, 70, 20.45, 20.45],

        //     ],
        //   },
        //   options: {
        //     axisX: {
        //       showGrid: false,
        //     },
        //     low: 0,
        //     high: 60,
        //     chartPadding: {
        //       top: 0,
        //       right: 5,
        //       bottom: 0,
        //       left: 0,
        //     },
        //   },
        //   responsiveOptions: [
        //     ['screen and (max-width: 640px)', {
        //       seriesBarDistance: 5,
        //       axisX: {
        //         labelInterpolationFnc: function (value) {
        //           return value[0]
        //         },
        //       },
        //     }],
        //   ],
        // },
      }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterFournisseurPaiement: 'getFournisseurPaiement',
        parameterModePaiement: 'getModePaiement',
        parameterFraisReActivation: 'getFraisReActivation',
      }),
      showContratAnnule () {
        return this.contrats.some(x => x.statut === 4)
      },
      getContrats () {
        if (this.btnContratAnnule) return this.contrats
        // On affiche juste les contrats pas annulés. Mais si il on une facture non payé, on l'ai affiche
        return this.contrats.filter(x => {
          // Si le contrat est pas annulé on l'affiche
          if (x.statut !== 4) {
            return x
          } else {
            // Si annulé, on regarde si il y a une facture impayé par carte de crédit. Si oui on affiche le contrat
            if (x.Factures.find(facture => facture.type === 2 && facture.montant_total > 0 && !facture.paiementId)) return x
          }
        })
      },
    },

    watch: {
      // Création du array 'expand' qui dépend du nombre de contrat
      contrats (val) {
        if (this.contrats) {
          // Création du array 'expand' qui dépend du nombre de contrat
          this.contrats.forEach(currentItem => {
            this.expand.push({ state: false })
          })
        }
      },
    },

    created () {
      // Pour les opération, on affiche le bouton 'Contrat'
      if (this.$route.name === 'operation-client' || this.$route.name === 'operation-contrat' || this.$route.name === 'operation-tools' || this.$route.name === 'DashboardChart') this.showRouteContract = true
      if (this.contrats) {
        // Création du array 'expand' qui dépend du nombre de contrat
        this.contrats.forEach(currentItem => {
          this.expand.push({ state: false })
        })
      }
    },

    async mounted () {
      this.getData()
    },

    methods: {
      ...mapMutations([
        'updateNewCard',
        'resetNewCard',
      ]),
      disabledCard (InscriptionPaiementId, cardId) {
        // On peu changé la carte de crédit seulement sur la route 'Dashboard'
        if (this.$route.name !== 'Dashboard') return true
        // On désactive si la carte est déjà sélectionné
        return InscriptionPaiementId === cardId
      },
      setAutomatiqueManuelPaiment (contrats) {
        this.contrats = contrats.map(item => {
          // true si il y a 'InscriptionPaiementId'
          item.paiementAutomatique = !!item.InscriptionPaiementId
          return item
        })

        // Trier les inscription paiements par nom
        this.contrats.forEach(currentItem => {
          currentItem.Client.Inscription_paiements.sort((a, b) => (a.nom_titulaire > b.nom_titulaire) ? 1 : -1)
        })
      },
      async getData () {
        try {
          // Start Spinner
          this.$loading.show()
          let contrats = []
          // On efface le id du contrat pour l'ajout d'une nouvelle carte. Voir le profile
          this.resetNewCard()
          // Pour le module Opération -> Client
          if (this.clientId) {
            // Pour le module Dashboard
            this.contracts = []
            // Récupération du client
            const client = await ClientsService.view(this.clientId)
            contrats = client.Contrats
          } else {
            if (this.clientContrats.length) {
              contrats = this.clientContrats
            }
          }

          // Pour le paiement automatique, on mets true ou false. A cause du bouton radio qui est plus facile avec un true/false
          this.setAutomatiqueManuelPaiment(contrats)

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      checkCol (index) {
        if (this.contrats.length === index + 1) {
          if (index + 1 % 2 !== 0) {
            return 12
          }
        }
        return 6
      },
      getContratName (id) {
        if (this.showRouteContract) return `[${id}] ${this.$i18n.t('Contract')}`
        return this.$i18n.t('Contract')
      },
      getContratTypeIcon (type) {
        if (type === 0) {
          return 'mdi-alert-box'
        } else if (type === 1) {
          return 'mdi-book-open-variant'
        } else if (type === 2) {
          return 'mdi-credit-card-check-outline'
        }
        return 'mdi-help'
      },
      getContratTypeColor (type) {
        if (type === 0) {
          return '#333333'
        } else if (type === 1) {
          return 'green'
        } else if (type === 2) {
          return 'primary'
        }
        return 'black'
      },
      checkPaimentSummary (contrat) {
        // Si ce n'est pas un contrat de type 2 (Carte de crédit) on affiche pas le bouton pour payer. On affiche pas le bouton
        if (contrat.type !== 2) return false

        // Si on se rend ici. C'est un contrat par carte de crédit et un paiement manuelle
        // On regarde si tous les paiements son fait et qui sont de catégorie 1 (Mensuelle), statut 1 (visible pour le client), total plus grand que 0 et pas de paiementId
        return contrat.Factures.filter(item => item.type === 2 && item.categorie === 1 && item.montant_total > 0 && (item.statut === 1 || item.statut === 2) && !item.paiementId).length > 0
      },
      checkPaimentText (factures) {
        // On ne garde que les factures qui n'ont pas de paiement (null) et qui sont de catégorie 1
        const billToPay = factures.filter(item => item.type === 2 && item.categorie === 1 && item.montant_total > 0 && (item.statut === 1 || item.statut === 2) && !item.paiementId)
        if (billToPay.length === 1) {
          // 1 facture impayée
          return `1 ${this.$i18n.t('contract.bill.Unpaid_bill')}`
        }
        // Plusieurs facture impayée
        return `${billToPay.length} ${this.$i18n.t('contract.bill.Unpaid_bills')}`
      },
      async showBill (contratId, index) {
        if (this.expand[index].state && !this.showContract) {
          this.expand[index].state = false
        } else {
          this.expand[index].state = true
        }
        this.showContract = false
      },
      editContract (contratId, index) {
        if (this.expand[index].state && this.showContract) {
          this.expand[index].state = false
        } else {
          this.expand[index].state = true
        }
        this.showContract = true

        this.$nextTick(() => {
          this.$refs.clientContratEdit[index].getContract(contratId, index)
        })
      },
      async createUpdateContract (contract, expandIndex, clientChange) {
        // Start Spinner
        this.$loading.show()
        try {
          await ContratsService.update(contract, contract.id)
          // Close expansion panel
          this.expand[expandIndex].state = false
          // Mise à jour du contrat
          const findContratIndex = this.contrats.findIndex(item => item.id === contract.id)
          this.contrats[findContratIndex] = contract
          // true si il y a 'InscriptionPaiementId'
          this.contrats[findContratIndex].paiementAutomatique = !!this.contrats[findContratIndex].InscriptionPaiementId
          // Le contrat à changer le client, il faut enlever le contrat de la liste
          if (clientChange) {
            // eslint-disable-next-line vue/no-mutating-props
            this.contrats.splice(expandIndex, 1)
          }

          // Si c'est la route 'operation-tools', on mets à jour le contrat qui sont en erreur d'intégité 'status === 6'
          if (this.$route.name === 'operation-tools') {
            // Voir le composant 'ToolsBillingManagement'
            this.$emit('update-contrats-integrite')
          }

          // Montre la confirmation
          this.$notifier.show({ message: this.$i18n.t('contract.snackBar.updated_successfully'), color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })
          // Stop Spinner
          this.$loading.hide()

          // Si le statut du contrat est passée en catégorie 7 (suspendu)
          this.createFactureCategorie2(contract)
        } catch (error) {
          // Stop Spinner
          this.$loading.hide()
          console.log(error)
        }
      },
      cancelContract (expandIndex) {
        // Close expansion panel
        this.expand[expandIndex].state = false
      },
      async createFactureCategorie2 (contract) {
        // Si le statut est 7 (suspendu) et qu'il n'y a pas de facture catégorie 2 de pas payé, il faut en créer une
        if (contract.statut === 7) {
          // Si il n'y a pas de facture de catégorie 2 qui n'est pas payé, il faut en créer une
          if (!contract.Factures.some(item => item.categorie === 2 && !item.paiementId)) {
            console.log(this.parameterFraisReActivation)

            Swal.fire({
              title: `${this.$i18n.t('contract.swal.title.Create_reactivation_invoice')}`,
              text: `${this.$i18n.t('contract.swal.text.Reactivation_fees')} ${this.parameterFraisReActivation}$ ${this.$i18n.t('contract.swal.text.Reactivation_fees2')}`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.$i18n.t('Yes'),
              cancelButtonText: this.$i18n.t('No'),
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  if (result) {
                    const facture = {
                      clientId: contract.ClientId,
                      contratId: contract.id,
                      categorie: 2,
                      contratNo: contract.no,
                    }
                    const response = await FacturesService.autreCreate(facture)
                    if (response) {
                      this.factureCategorie2Dialog.state = true
                      this.factureCategorie2Dialog.message = `${this.$i18n.t('contract.dialog.suspension_invoice')} [${contract.id}] ${contract.no}`
                    }

                    // On update les factures autres. Voir le composant 'FactureAutre.vue'
                    EventBus.$emit('update-facture-autre', contract.ClientId)
                  }
                } catch (error) {
                  console.log(error)
                }
              }
            })
          }
        }

        // try {
        //   // Si le statut est 7 (suspendu) et qu'il n'y a pas de facture catégorie 2 de pas payé, il faut en créer une
        //   if (contract.statut === 7) {
        //     // Si il n'y a pas de facture de catégorie 2 qui n'est pas payé, il faut en créer une
        //     if (!contract.Factures.some(item => item.categorie === 2 && !item.paiementId)) {
        //       const facture = {
        //         clientId: contract.ClientId,
        //         contratId: contract.id,
        //         categorie: 2,
        //         contratNo: contract.no,
        //       }
        //       const result = await FacturesService.autreCreate(facture)
        //       if (result) {
        //         this.factureCategorie2Dialog.state = true
        //         this.factureCategorie2Dialog.message = `${this.$i18n.t('contract.dialog.suspension_invoice')} [${contract.id}] ${contract.no}`
        //       }
        //     }
        //   }
        //   // On update les factures autres. Voir le composant 'FactureAutre.vue'
        //   EventBus.$emit('update-facture-autre', contract.ClientId)
        //   // Montre la confirmation
        //   this.$notifier.show({ message: this.$i18n.t('contract.snackBar.updated_successfully'), color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })
        //   // Stop Spinner
        //   this.$loading.hide()
        // } catch (error) {
        //   console.log(error)
        //   // Stop Spinner
        //   this.$loading.hide()
        // }
      },
      async changePaiementAutomatique (contractId, clientId, InscriptionPaiementId) {
        // Start Spinner
        this.$loading.show()
        try {
          await ContratsService.update({ InscriptionPaiementId: InscriptionPaiementId }, contractId)
          this.resetContrats(clientId)
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async changePaiementManuel (contractId, clientId) {
        // Start Spinner
        this.$loading.show()
        try {
          await ContratsService.update({ InscriptionPaiementId: null }, contractId)
          this.resetContrats(clientId)
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async changeCreditCard (contractId, inscriptionPaiementId, clientId) {
        // Start Spinner
        this.$loading.show()
        try {
          await ContratsService.update({ InscriptionPaiementId: inscriptionPaiementId }, contractId)
          this.resetContrats(clientId)
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async resetContrats (clientId) {
        // Start Spinner
        this.$loading.show()
        try {
          const client = await ClientsService.view(clientId)
          // Pour le paiement automatique, on mets true ou false. A cause du bouton radio qui est plus facile avec un true/false
          this.setAutomatiqueManuelPaiment(client.Contrats)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      addCard (contratId) {
        // On mets le contrat id dans le store. Quand on ajoute la carte de crédit elle est ajouté à ce contrat. Voir le view Profile fonction 'resetNewCard'
        this.updateNewCard(contratId)
        this.$router.push({ name: 'profile' })
      },
    },
  }
</script>

<style scoped>
  .pointer {
    cursor: pointer;
  }
  .description {
    margin-left: 102px;
  }
  .descriptionWithoutButton {
    margin-left: 65px;
  }
  .descriptionWithDescription {
    margin-left: 55px;
  }
  .descriptiontestSuspend {
    margin-left: 53px;
  }
  .descriptiontestSuspend1 {
    margin-left: 93px;
  }
</style>
